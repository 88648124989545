import React from 'react'
import logo from '../img/mark.png'
import {StaticQuery, graphql} from 'gatsby'
import Form from '../components/Form'

export default() => (<StaticQuery query={graphql ` query ContactFormQuery {
      markdownRemark(frontmatter : {
            path: {
              eq: "/contact"
            }
          }) {
            frontmatter {
              path
              title
              cta
            }
            html
          }
        }
      `} render={data => (
        <section className="contact-form-contain">
          <div className="container">
            <div className="columns">
              <div className="column is-7 cta">
                <img src={logo} alt="Joint Force Leadership" className="logo-small"/>
                <h2 className="is-size-3">{data.markdownRemark.frontmatter.cta}</h2>
                <p> We want to help you and your team reach your full potential.  Contact us to get started on your leadership journey: </p>
                <Form/>
              </div>
            </div>
          </div>
      </section>
      )}
      />
    );
