import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Img from "gatsby-image";

//Components
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'

export default class OurSystemPage extends React.Component {
  render() {
    //Get Page Data
    const {data} = this.props

    // Parse Content for System Section
    return (<Layout>
      <div className="primary-contain">
        <Hero headerimg={data.pagecontent.frontmatter.image.childImageSharp.fluid}/>
        <div className="secondary-contain">
          <section className="columns">
            <div className="column is-10 is-offset-1">
              <img className='systemgraphic' src="/img/graphic.png"/>
              <h1 className="is-size-2">{data.pagecontent.frontmatter.title}</h1>
              <p>Leadership is about people, pure and simple.  It involves having the right people in the right place doing the right thing at the right time.  It is hard work requiring constant effort sustained over long periods, and it is not easy.  Our core principles of focus, trust, and communication form the foundation of effective leadership.</p>
              <div className='system-seg first'>

                <h2><img className='systemgraphic' src="/img/focus.png"/>Focus</h2>
                <p>Focus starts with being the master of right now.  If you focus on success with specific goals, put team above self, and care for others more than you care about yourself, you have taken the first steps to success.  Couple that with a solid grounding in the fundamentals while remaining technically and tactically proficient, and you will be well on the path toward our second leadership principle -- gaining and maintaining trust.</p>
              </div>
              <div className='system-seg'>

                <h2><img className='systemgraphic' src="/img/trust.png"/>Trust</h2>
                <p>Trust is the glue binding team and leader.  Leaders must start by earning the trust of their teams every day, then demonstrate through actions that they have trust in their teams. Team members must trust each other to enjoy success as a group, and possess the character to trust in themselves. Couple that with trust in the process, and you will be ready to fold in our third leadership principle -- communication.</p>
              </div>
              <div className='system-seg'>

                <h2><img className='systemgraphic' src="/img/communicate.png"/>Communication</h2>
                <p>The art of communication is the language of leadership.  Commander’s intent lets every person on the team know exactly where we are going, and is delivered with a constant message using every possible medium available.  Effective communication requires candor, including those tough grown up talks, and must happen on a regular basis.  Leaders must communicate horizontally and vertically to ensure clear reception. Finally, we must strive the build a common language so that messages are received and understand as they were intended.  When you add effective communication to a team with focus and trust, the sky's the limit.</p>
              </div>
            </div>
          </section>
          <ContactForm contactdata={data.pagecontent}/>
        </div>
      </div>
    </Layout>)
  }
}
export const pageQuery = graphql ` query OurSystemQuery {

        pagecontent: markdownRemark(frontmatter : {
          path: {
            eq: "/our-system"
          }
        }) {
          id
          frontmatter {
            path
            title
            image {
              childImageSharp {
                fluid(maxWidth : 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
      `
